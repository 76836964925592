import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/Layout';
import Section from '../components/Section';
import { Container, Row, Column } from '../components/Grid';
import Hero from '../components/Hero';
import Image from '../components/Image';
import { Trans, useTranslation } from 'react-i18next';
import CareerCallout from './partials/_CareerCallout';
import HeroImage from '../images/hero/hero-cybersecurity.png';

const CyberSecurityPage = ({ data }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(['cybersecurity', 'common', 'career-profiles']);

  return (
    <Layout title="Cyber Security">
      <Hero title={t('common:cybersecurityAndStem')} image={HeroImage} />
      <Section>
        <Container>
          <h2>{t('activateSuperpowersHeader')}</h2>
          <Row>
            <Column size={8}>
              <p>
                <Trans
                  i18nKey="cybersecurity:activateSuperpowersText"
                  components={{
                    link1: (
                      <Link
                        to={`/${language}/career-profiles`}
                        className="text-bold"
                      />
                    ),
                  }}
                />
              </p>
            </Column>
          </Row>
        </Container>
      </Section>
      <div className="cybersecurity__disciplines">
        <Section>
          <Container>
            <Row verticalAlignCenter>
              <Column xsmall={6} xsmallOffset={3} medium={2} mediumOffset={0}>
                <Image filename="icon-all-things-digital.svg" />
              </Column>
              <Column medium={8} mediumOffset={1}>
                <h2>{t('common:allThingsDigital')}</h2>
                <p>
                  <strong>{t('allThingsDigitalTextBold')}</strong>{' '}
                  {t('allThingsDigitalText')}
                </p>
              </Column>
            </Row>
          </Container>
        </Section>
        <Section>
          <Container>
            <Row verticalAlignCenter>
              <Column xsmall={6} xsmallOffset={3} medium={2} mediumOffset={1}>
                <Image filename="icon-cyrptology.svg" />
              </Column>
              <Column medium={8}>
                <h2>{t('common:cryptology')}</h2>
                <p>
                  <strong>{t('cryptologyTextBold')}</strong>{' '}
                  {t('cryptologyText')}
                </p>
              </Column>
            </Row>
          </Container>
        </Section>
        <Section>
          <Container>
            <Row verticalAlignCenter>
              <Column xsmall={6} xsmallOffset={3} medium={2} mediumOffset={0}>
                <Image filename="icon-fraud-detection.svg" />
              </Column>
              <Column medium={8} mediumOffset={1}>
                <h2>{t('common:fraudDetection')}</h2>
                <p>
                  <strong>{t('fraudTextBold')}</strong> {t('fraudText')}
                </p>
              </Column>
            </Row>
          </Container>
        </Section>
        <Section>
          <Container>
            <Row verticalAlignCenter>
              <Column xsmall={6} xsmallOffset={3} medium={2} mediumOffset={1}>
                <Image filename="icon-cybersecurity.svg" />
              </Column>
              <Column medium={8}>
                <h2>{t('common:cybersecurity')}</h2>
                <p>
                  <strong>{t('cybersecurityTextBold')}</strong>{' '}
                  {t('cybersecurityText')}
                </p>
              </Column>
            </Row>
          </Container>
        </Section>
        <Section>
          <Container>
            <Row verticalAlignCenter>
              <Column xsmall={6} xsmallOffset={3} medium={2} mediumOffset={0}>
                <Image filename="icon-internet-of-things.svg" />
              </Column>
              <Column medium={8} mediumOffset={1}>
                <h2>{t('internetOfThingsHeader')}</h2>
                <p>
                  <strong>{t('internetOfThingsTextBold')}</strong>{' '}
                  {t('internetOfThingsText')}
                </p>
              </Column>
            </Row>
          </Container>
        </Section>
        <Section>
          <Container>
            <Row verticalAlignCenter>
              <Column xsmall={6} xsmallOffset={3} medium={2} mediumOffset={1}>
                <Image filename="icon-ai-social-good.svg" />
              </Column>
              <Column medium={8}>
                <h2>{t('common:aiForGood')}</h2>
                <p>
                  <strong>{t('aiForSocialGoodTextBold')}</strong>{' '}
                  {t('aiForSocialGoodText')}
                </p>
              </Column>
            </Row>
          </Container>
        </Section>
      </div>
      <CareerCallout
        title={t('footerCareerCalloutHeader')}
        description={
          <p
            dangerouslySetInnerHTML={{
              __html: t('footerCareerCalloutBody'),
            }}
          />
        }
        path={`/${language}${t('footerCareerCalloutURL')}`}
        img={t('career-profiles:directorOfSoftwareEngineeringQuoteImage')}
        name={t('footerCareerCalloutName')}
        job={t('footerCareerCalloutJob')}
      />
    </Layout>
  );
};

export default CyberSecurityPage;
